.table_ledger {
    thead {
        background-color: rgba(170, 207, 208, 0.2);
        color: $primary;
        th {
            &:first-child {
                padding-left: 2rem;
            }
            &:last-child {
                padding-right: 2rem;
            }
        }
    }
    tbody {
        tr {
            td {
                vertical-align: middle;
                &:first-child {
                    padding-left: 2rem;
                }
                &:last-child {
                    padding-right: 2rem;
                }
                .date {
                    font-size: .9rem;
                    margin: 0;
                }
                p {
                    margin: 0;
                }
            }
        }
    }
    tfoot {
        tr {
            td {
                &:first-child {
                    padding-left: 2rem;
                }
                &:last-child {
                    padding-right: 2rem;
                }
            }
        }
    }
}

.ledger_head {
    display: flex;
    padding: 1rem 2rem;
    .photo {
        padding-right: 1rem;
        img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
        }
    }
    .info {
        h3 {
            margin: 0;
        }
        p {
            margin: 0;
        }
    }
}

.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem 1rem;
    img {
        max-width: 300px;
    }
}