.wrapper {
    display: flex;
    width: 100%;
    background-color: rgb(241, 245, 249);
    min-height: 100vh;



    .left-side {
        overflow-x: scroll;
        
        // -webkit-box-shadow: inset -3px 0px 8px -4px rgb(0 0 0 / 10%);
        // -moz-box-shadow: inset -3px 0px 8px -4px rgba(0, 0, 0, 0.1);
        // box-shadow: inset -3px 0px 8px -4px rgb(0 0 0 / 7%);
        height: 100%;
        min-height: 100vh;
        width: 250px;
        position: fixed;
        left: 0;
        top: 50px;
        z-index: 1;

        .sidebar {
            display: flex;
            flex-direction: column;
            height: 100%;
            
        }
    }

    .right-side {

        padding-left: 250px;
        padding-top: 60px;
        width: 100%;


        .content-header {
            display: flex;
            justify-content: space-between;
            background: $themeLight;
            box-shadow: 1px 1px 2px rgb(0 0 0 / 10%);
            align-items: center;
            padding: .8rem 2rem;
            border-bottom-left-radius: 20px;
            .left {}

            .right {
                display: flex;
                justify-content: flex-end;

                .button {
                    margin-left: .5rem;
                }
            }

            h1 {
                font-size: 1.5rem;
                margin: 0;
                color: $primary;
                font-weight: bold;
            }
        }

        >.content {

            padding: 2rem;
            position: relative;


        }
    }
}


@media screen and (max-width: 767px) {
    .wrapper {
        display: block;

        .left-side {
            transform: translateX(-100%);
            transition: all 0.5s ease;

            &.active {
                transform: translateX(0);

            }
        }

        .right-side {
            padding-left: 0;

            >.content {
                padding: 1rem;
            }

            .content-header {
                flex-direction: column;
                align-items: flex-start;
                padding: 0.5rem 1rem;

                h1 {
                    font-weight: 600;
                    font-size: 1.2rem;
                    margin-bottom: .3rem;

                }


            }
        }
    }
}