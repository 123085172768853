@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700;800&display=swap");
.simple-section {
  padding: 1rem 0;
  position: relative;
}

.section-title {
  font-size: 2.27rem;
  font-weight: 600;
  color: #121606;
  text-align: center;
  position: relative;
  margin-bottom: 3rem;
  font-family: "Barlow", sans-serif;
}
.section-title::after {
  content: "";
  display: block;
  width: 80px;
  height: 3px;
  background-color: #34495E;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -12px;
  margin: auto;
  border-radius: 1rem;
}
.section-title.title-sm {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
.section-title.title-left {
  text-align: left;
}
.section-title.title-left::after {
  margin-left: 0;
}

.text-red {
  color: #F2555E;
}

.text-green {
  color: #49B76F;
}

.link_back {
  display: inline-flex;
  align-items: center;
}
.link_back svg {
  margin-right: 0.8rem;
}

.list-bullets {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
}
.list-bullets li {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.125rem;
  position: relative;
  padding-left: 1.4rem;
}
.list-bullets li::before {
  font-family: remixicon !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  color: #34495E;
  font-size: 0.7rem;
  top: 0.4rem;
}
.list-bullets.link-white li {
  color: #FFF;
}
.list-bullets.link-white li::before {
  color: #FFF;
}

.text-right {
  text-align: right;
}

@media screen and (max-width: 767px) {
  .section-title {
    font-size: 2rem;
  }

  .dash-head .breadcrumb {
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 1rem;
  }

  .body-wrap {
    padding-top: 5rem;
  }
}
.alert {
  border-radius: 0.4rem;
}

body {
  font-family: "Barlow", sans-serif;
  background-color: #EBEDF4;
  font-weight: normal;
}

a {
  color: #34495E;
  text-decoration: none;
}
a:hover, a:focus, a:active {
  color: #AACFD0;
}

#main {
  padding-top: 8rem;
}

.button {
  display: inline-block;
  vertical-align: middle;
  padding: 0.6rem 1.3rem;
  border: none;
  text-align: center;
  transition: all 0.4s ease-in-out;
  border: 1px solid transparent;
  font-size: 1rem;
  border-radius: 60px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
}
.button.hover-on-dark:hover {
  color: #FFF;
  border-color: #FFF;
}

.button-long {
  min-width: 130px;
}

.button-dark {
  background-color: #191824;
  color: #FFF;
}
.button-dark:hover {
  background-color: transparent;
  border-color: #191824;
  color: #191824;
}

.button-icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 0.6rem 0.6rem 1.3rem;
  white-space: nowrap;
}
.button-icon span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}
.button-icon.icon-right span {
  margin-left: 0.8rem;
}
.button-icon.icon-left {
  flex-direction: row-reverse;
  justify-content: center;
  padding: 0.6rem 1rem 0.6rem 1rem;
}
.button-icon.icon-left span, .button-icon.icon-left i {
  margin-left: 0;
  margin-right: 0.4rem;
}
.button-icon.button-dark span {
  background-color: #EBEDF4;
  color: #191824;
}

.button-white {
  background-color: #FFF;
  box-shadow: 0 3px 20px #0000000b;
  color: #000;
}

.button-light {
  background-color: #E8E8E8;
  color: #65676B;
}
.button-light:hover {
  background-color: transparent;
  border-color: #65676B;
  color: #65676B;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.button-primary {
  background-color: #34495E;
  color: #FFF;
  font-weight: bold;
  border: 1px solid transparent;
}
.button-primary:hover {
  background-color: transparent;
  border-color: #34495E;
  color: #34495E;
  opacity: 1;
}

.button-secondary {
  background-color: #AACFD0;
  color: #FFF;
  font-weight: bold;
}
.button-secondary:hover {
  background-color: transparent;
  border-color: #AACFD0;
  color: #AACFD0;
  opacity: 1;
}

.button-outline-secondary {
  border-color: #AACFD0;
  color: #AACFD0;
}
.button-outline-secondary:hover {
  color: #FFF;
  background-color: #AACFD0;
}

.button-outline-primary {
  border-color: #34495E;
  color: #34495E;
  background-color: transparent;
}
.button-outline-primary:hover {
  color: #FFF;
  background-color: #34495E;
}

.button-fb {
  background-color: #3C5A9A;
  color: #FFF;
  font-weight: bold;
}
.button-fb:hover {
  background-color: transparent;
  border-color: #3C5A9A;
  color: #3C5A9A;
  opacity: 1;
}

.button-success {
  background-color: #49B76F;
  color: #FFF;
}
.button-success:hover {
  color: #FFF;
  opacity: 0.8;
}

.button-danger {
  background-color: #F2555E;
  color: #FFF;
}
.button-danger:hover {
  color: #FFF;
  opacity: 0.8;
}

.button-round {
  border-radius: 0.4rem;
}

.button-block {
  width: 100%;
  display: block;
}

.button-outline-gray {
  background-color: #F5F5F5;
  border: 1px solid #707070;
  color: #292724;
  font-weight: bold;
}
.button-outline-gray:hover {
  color: #292724;
  background-color: #FFF;
}

.button-large-icon {
  display: flex;
  flex-direction: column;
  line-height: 3rem;
}
.button-large-icon .icon {
  font-size: 3rem;
}
.button-large-icon .label {
  font-size: 1.5rem;
}

.input-icon {
  position: relative;
}
.input-icon .form-control {
  min-height: 52px;
  border-radius: 50px;
  padding-left: 4rem;
  color: #34495E;
}
.input-icon .form-control:focus {
  outline: 1px solid #34495E;
  box-shadow: none;
}
.input-icon span {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(215, 36, 29, 0.2);
  position: absolute;
  left: 10px;
  top: 8px;
  border-radius: 50%;
  color: #191824;
  font-size: 1.6rem;
}

.form-control:focus,
.form-select:focus {
  outline: 1px solid #34495E;
  box-shadow: none;
}
.form-control:focus ~ label,
.form-select:focus ~ label {
  color: #34495E;
}

#CountryCodes {
  width: 100%;
  height: 56px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#inputMobile {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.form-group {
  position: relative;
}
.form-group label {
  margin-bottom: 0.3rem;
  color: rgba(0, 0, 0, 0.9);
  font-weight: bold;
}
.form-group .form-control,
.form-group .form-select {
  min-height: 56px;
}
.form-group .form-control:placeholder,
.form-group .form-select:placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.form-group .input-action {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: #AACFD0;
  border: none;
  position: absolute;
  right: 5px;
  top: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.form-group .input-action:hover {
  background-color: #34495E;
}
.form-group .input-action:hover svg path {
  stroke: #FFF;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.radio-logos {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.radio-logos li {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 2rem 0 0 0;
  border-radius: 0.4rem;
  position: relative;
  background-color: #FFF;
  flex-basis: 14%;
  margin: 1%;
}
.radio-logos li:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.radio-logos li input {
  opacity: 0;
  position: absolute;
  left: 0;
}
.radio-logos li input + label {
  display: block;
  cursor: pointer;
  text-align: center;
}
.radio-logos li input + label::before {
  font-family: remixicon !important;
  font-weight: 400;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: auto;
  font-size: 1.3rem;
}
.radio-logos li input + label img {
  max-width: 60px;
  margin-bottom: 0.5rem;
}
.radio-logos li input:checked + label::before {
  content: "";
  color: #34495E;
}
.radio-logos li input:checked + label .count {
  background-color: #AACFD0;
  color: #FFF;
}

.breadcrumb {
  margin: 0.4rem 0 0 0;
  padding: 0;
}
.breadcrumb li {
  font-size: 0.8rem;
}
.breadcrumb li a {
  color: #121606;
}
.breadcrumb li.active {
  color: rgba(0, 0, 0, 0.5);
}

.breadcrumb-item + .breadcrumb-item::before {
  color: rgba(0, 0, 0, 0.5);
}

.card {
  border-radius: 0.4rem;
}
.card .card-title-dash {
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1rem;
}
.card.card-welcome {
  padding: 0.5rem 1.5rem;
}
.card.card-welcome p {
  margin: 0;
}
.card.card-welcome h4 {
  font-family: "Barlow", sans-serif;
  font-size: 2rem;
}
.card.card-welcome h4 span {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 300;
}
.card.card-welcome .logged-in {
  color: rgba(0, 0, 0, 0.4);
}
.card.card-welcome .logged-in span {
  color: #34495E;
  font-weight: 500;
}

.card-title {
  margin-bottom: 0.9rem;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.6);
  position: relative;
}
.card-title .card-action {
  position: absolute;
  right: 0;
  top: 0;
}
.card-title .card-action .button {
  padding: 0.1rem 0.8rem;
  font-size: 0.8rem;
}

.equal-cards .card {
  min-height: 120px;
}

.card-title-2 {
  position: relative;
  font-family: "Barlow", sans-serif;
  font-size: 1.2rem;
  display: flex;
  padding-left: 2rem;
}
.card-title-2 i {
  margin-right: 0.4em;
  color: #AACFD0;
  position: absolute;
  left: 0;
  top: -3px;
  font-size: 1.5rem;
}

.card_button {
  display: flex;
  background-color: #FFF;
  padding: 2rem;
  border-radius: 0.4rem;
  position: relative;
  border: 2px solid transparent;
  transition: all 0.5s ease;
}
.card_button:hover {
  border-color: #34495E;
}
.card_button > a {
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
.card_button .chevron {
  position: absolute;
  right: 2rem;
  top: 50%;
  margin-top: -8px;
}
.card_button .icon {
  width: 70px;
}
.card_button .info {
  padding-left: 1rem;
}
.card_button .info p {
  margin: 0;
}
.card_button .info h3 {
  margin: 0;
  font-weight: bold;
}

.theme-tabs {
  border: none;
}
.theme-tabs .nav-item .nav-link {
  background-color: #e8eaf2;
  color: #2b2b2b;
  font-size: 1.1em;
  font-weight: 500;
  padding: 1em 2em;
  border: none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.theme-tabs .nav-item .nav-link.active, .theme-tabs .nav-item .nav-link:hover, .theme-tabs .nav-item .nav-link:focus {
  background-color: #34495E;
  color: #FFF;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

#myTabContent {
  margin-top: -4px;
  z-index: 1;
  position: relative;
}

.tab-content-inner {
  background-color: #FFF;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 3em 2em;
}

.tab-style-2 {
  justify-content: center;
}
.tab-style-2 .nav-item .nav-link {
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  border-top: none;
  border-right: none;
  border-bottom: 3px solid #eceeef;
  border-left: none;
  padding: 15px 60px 10px 60px;
  font-size: 1.2rem;
}
.tab-style-2 .nav-item .nav-link span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px auto;
  color: #121606;
  font-size: 1rem;
  font-weight: 400;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: bold;
  background-color: #EBEDF4;
}
.tab-style-2 .nav-item .nav-link.active, .tab-style-2 .nav-item .nav-link:hover {
  border-bottom-color: #34495E;
  color: #34495E;
}
.tab-style-2 .nav-item .nav-link.active span, .tab-style-2 .nav-item .nav-link:hover span {
  background-color: #34495E;
  color: #FFF;
}

.tab-style-3 .nav-item .nav-link {
  text-align: center;
  color: #34495E;
  font-weight: normal;
  border-top: none;
  border-right: none;
  border-bottom: 5px solid transparent;
  border-left: none;
  padding: 5px 40px;
}
.tab-style-3 .nav-item .nav-link span {
  display: block;
  color: #121606;
  border-bottom: 3px solid #FFF;
}
.tab-style-3 .nav-item .nav-link.active, .tab-style-3 .nav-item .nav-link:hover {
  background-color: #34495E;
  color: #FFF;
}
.tab-style-3 .nav-item .nav-link.active span, .tab-style-3 .nav-item .nav-link:hover span {
  color: #FFF;
}

.user_item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.8rem 2rem;
  position: relative;
}
.user_item:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.user_item > svg {
  display: none;
}
.user_item:last-child {
  border-bottom: none;
}
.user_item .user {
  display: flex;
  align-items: center;
  flex-basis: 50%;
}
.user_item .user .photo {
  width: 80px;
}
.user_item .user .photo img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.user_item .user .info h3 {
  margin: 0;
  font-size: 1.2rem;
}
.user_item .user .info p {
  margin: 0;
}
.user_item .amount {
  font-size: 1.3rem;
  min-width: 135px;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  .user_item {
    padding: 1rem 0.4rem;
    flex-wrap: wrap;
    justify-content: center;
  }
  .user_item .user .photo {
    width: 58px;
  }
  .user_item .user .photo img {
    width: 50px;
    height: 50px;
  }
  .user_item .user .info p {
    font-size: 0.8rem;
  }
  .user_item .button {
    margin-top: 0.4rem;
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
  }
}
.table_ledger thead {
  background-color: rgba(170, 207, 208, 0.2);
  color: #34495E;
}
.table_ledger thead th:first-child {
  padding-left: 2rem;
}
.table_ledger thead th:last-child {
  padding-right: 2rem;
}
.table_ledger tbody tr td {
  vertical-align: middle;
}
.table_ledger tbody tr td:first-child {
  padding-left: 2rem;
}
.table_ledger tbody tr td:last-child {
  padding-right: 2rem;
}
.table_ledger tbody tr td .date {
  font-size: 0.9rem;
  margin: 0;
}
.table_ledger tbody tr td p {
  margin: 0;
}
.table_ledger tfoot tr td:first-child {
  padding-left: 2rem;
}
.table_ledger tfoot tr td:last-child {
  padding-right: 2rem;
}

.ledger_head {
  display: flex;
  padding: 1rem 2rem;
}
.ledger_head .photo {
  padding-right: 1rem;
}
.ledger_head .photo img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.ledger_head .info h3 {
  margin: 0;
}
.ledger_head .info p {
  margin: 0;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem 1rem;
}
.empty img {
  max-width: 300px;
}

.wizard {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}
.wizard li {
  position: relative;
  margin: 0 1rem;
  z-index: 1;
}
.wizard li::after {
  content: "";
  display: block;
  width: 114px;
  height: 3px;
  background-color: #EBEDF4;
  position: absolute;
  top: 50%;
  border-radius: 30px;
  right: -73px;
  z-index: -1;
  margin-top: -13px;
}
.wizard li:last-child::after {
  display: none;
}
.wizard li a {
  text-align: center;
  display: inline-block;
  color: #2A2D34;
}
.wizard li a .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EBEDF4;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: auto;
  color: #121606;
  font-size: 1.2rem;
  border: 5px solid #FFF;
}
.wizard li a .label {
  display: block;
  margin-top: 0.3rem;
  font-size: 13px;
}
.wizard li.active a .icon {
  background-color: #34495E;
  color: #FFF;
}
.wizard li.active::after {
  background-color: #34495E;
}
.wizard li.completed a .icon {
  background-color: #49B76F;
  color: #FFF;
}
.wizard li.completed::after {
  background-color: #49B76F;
}

@media screen and (max-width: 767px) {
  .wizard {
    overflow-y: scroll;
  }
  .wizard li::after {
    top: 39%;
  }
  .wizard li a .label {
    line-height: 14px;
  }
}
.table thead tr th {
  font-size: 0.9rem;
}

.va-middle td,
.va-middle th {
  vertical-align: middle;
}

.table-add-sale .col-input {
  max-width: 70px;
}

.scrollview {
  overflow-x: hidden;
  overflow-y: scroll;
}

/* Fix table head */
.tableFixHead th {
  position: sticky;
  top: 0;
  background-color: #FFF;
  z-index: 1;
}

.table-notifications .userinfo {
  display: flex;
}
.table-notifications .userinfo .photo {
  width: 50px;
}
.table-notifications .userinfo .photo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.table-notifications .userinfo .info h3 {
  font-size: 1.2rem;
  margin: 0;
}
.table-notifications .userinfo .info p {
  font-size: 0.9rem;
  margin: 0;
}
.table-notifications .button {
  font-size: 0.9rem;
  padding: 0.3rem 0.7rem;
}
.table-notifications .action-buttons {
  display: flex;
  align-items: center;
}

.table-card {
  border-spacing: 0 10px;
  border-collapse: separate;
}
.table-card thead tr {
  background-color: rgba(0, 0, 0, 0.08);
}
.table-card thead tr th {
  padding: 0.75rem 1.25rem;
  font-weight: bold;
  white-space: nowrap;
}
.table-card tbody tr td {
  box-shadow: 20px 3px 20px #0000000b;
  padding: 0.75rem 1.25rem;
  border-bottom-width: 0px;
  background-color: white;
}
.table-card td {
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .tbl-responsive {
    border: 0;
  }
  .tbl-responsive.table-striped tr td {
    --bs-table-accent-bg: #FFF;
  }
  .tbl-responsive thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .tbl-responsive tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 1rem;
    box-shadow: rgba(26, 108, 225, 0.1) 0px 0px 25px;
    background-color: #FFF;
    padding: 18px;
    border-radius: 5px;
  }
  .tbl-responsive td {
    border-bottom: 1px solid #ddd;
    display: block;
    text-align: right;
    padding: 0.3rem 0.1rem;
  }
  .tbl-responsive td::before {
    content: attr(data-label);
    float: left;
  }
  .tbl-responsive td:last-child {
    border-bottom: 0;
  }
}
.sugguestions-wrap {
  position: relative;
}

.suggestion-box {
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #f6f6f6;
  z-index: 1;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.4rem;
}

.user_suggestion .user {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
}
.user_suggestion .user .photo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.user_suggestion .user .info {
  padding-left: 1rem;
}
.user_suggestion .user .info h3 {
  font-size: 1rem;
  margin: 0;
}
.user_suggestion .user .info p {
  font-size: 0.9rem;
  margin: 0;
}

header {
  background-color: #FFF;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  padding: 0.1rem 0;
}
header .navbar-toggler {
  color: #FFF !important;
  font-size: 46px;
  padding: 0;
  border: none;
}
header .navbar-brand img {
  max-width: 140px;
}
header .notifications {
  margin-right: 2rem;
}
header .notifications a {
  position: relative;
}
header .notifications a span {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #F2555E;
  color: #FFF;
  position: absolute;
  right: -10px;
  top: -4px;
  font-size: 1rem;
}
header .notifications a i {
  font-size: 2rem;
}
header .user-logged-in {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
header .user-logged-in .photo {
  margin-right: 0.6rem;
}
header .user-logged-in .photo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}
header .user-logged-in .photo a {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #AACFD0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
header .user-logged-in .photo a:hover, header .user-logged-in .photo a:focus {
  background-color: #34495E;
  color: #FFF;
}
header .user-logged-in .info h4 {
  font-size: 1.1rem;
  margin: 0;
}
header .user-logged-in .info p {
  font-size: 0.9rem;
  margin: 0;
}
header .nav-right .nav-item .nav-link {
  color: #34495E;
  text-transform: uppercase;
  font-size: 1rem;
  padding-right: 2.5rem;
  font-weight: 500;
}
header .nav-right .nav-item .nav-link.active, header .nav-right .nav-item .nav-link:hover, header .nav-right .nav-item .nav-link:focus {
  color: #AACFD0;
}
header .nav-right .nav-item .button-sm {
  font-size: 0.9rem;
  font-weight: normal;
  padding: 0;
  padding: 0.5rem 1rem;
}

#toggleSideNav {
  display: none;
}

@media screen and (max-width: 767px) {
  .header .nav-right {
    display: none;
  }
  .header .navbar-primary .header-pump-code {
    margin-left: 0;
    color: #FFF;
    font-weight: normal;
    font-size: 0.7rem;
  }
  .header .navbar-primary .header-pump-code span {
    display: none;
  }
  .header .navbar-primary .container-fluid {
    flex-wrap: nowrap;
  }
  .header .navbar-primary .container-fluid #toggleSideNav {
    display: block;
    color: #AACFD0;
    font-size: 1.8rem;
  }
  .header .navbar-primary .container-fluid #toggleSideNav .close-icon {
    display: none;
  }
  .header .navbar-primary .container-fluid #toggleSideNav.close .menu-icon {
    display: none;
  }
  .header .navbar-primary .container-fluid #toggleSideNav.close .close-icon {
    display: block;
  }
}
.page-head {
  background: linear-gradient(90deg, #3C40C6 0%, #FF6D4C 100%);
  padding-bottom: 1rem;
  margin-bottom: 3rem;
}
.page-head .breadcrumb {
  justify-content: center;
}

footer {
  background-color: #F8F8F8;
  position: relative;
  padding-top: 3rem;
}
footer .footer-widget h4 {
  font-weight: 600;
  color: #121606;
  font-size: 1rem;
  margin-bottom: 1rem;
}
footer .footer-widget p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;
}
footer .footer-widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
footer .footer-widget ul li {
  margin-bottom: 0.5rem;
}
footer .footer-widget ul li a {
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.9rem;
}
footer .footer-widget ul li a:hover {
  color: #34495E;
}
footer .copyright {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
  margin-top: 1rem;
}
footer .copyright .row {
  align-items: center;
}
footer .copyright .footer-social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
footer .copyright .footer-social li {
  margin-left: 1rem;
  font-size: 1.5rem;
}
footer .copyright .footer-social li a {
  color: rgba(0, 0, 0, 0.5);
}

.cta-wrap {
  position: relative;
  margin-top: 4rem;
}

.call-to-action {
  background: linear-gradient(92.22deg, #751C1C 2.32%, #A6201D 52.53%, #D7241D 98.14%);
  border-radius: 0.4rem;
  padding: 3.75rem;
}
.call-to-action h5 {
  color: #FFF;
  font-size: 1.2rem;
  font-weight: 300;
}
.call-to-action h1 {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #EBEDF4;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  font-weight: bold;
}
.call-to-action p {
  color: #FFF;
  font-size: 1.1rem;
}
.call-to-action .cta-button {
  text-align: right;
}

.footer-social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-social li a {
  display: block;
  margin-left: 1rem;
}
.footer-social li a i {
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .call-to-action {
    padding: 2.75rem;
    text-align: center;
  }
  .call-to-action .cta-button {
    text-align: center;
  }
}
.wrapper {
  display: flex;
  width: 100%;
  background-color: #f1f5f9;
  min-height: 100vh;
}
.wrapper .left-side {
  overflow-x: scroll;
  height: 100%;
  min-height: 100vh;
  width: 250px;
  position: fixed;
  left: 0;
  top: 50px;
  z-index: 1;
}
.wrapper .left-side .sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.wrapper .right-side {
  padding-left: 250px;
  padding-top: 60px;
  width: 100%;
}
.wrapper .right-side .content-header {
  display: flex;
  justify-content: space-between;
  background: #EBEDF4;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 0.8rem 2rem;
  border-bottom-left-radius: 20px;
}
.wrapper .right-side .content-header .right {
  display: flex;
  justify-content: flex-end;
}
.wrapper .right-side .content-header .right .button {
  margin-left: 0.5rem;
}
.wrapper .right-side .content-header h1 {
  font-size: 1.5rem;
  margin: 0;
  color: #34495E;
  font-weight: bold;
}
.wrapper .right-side > .content {
  padding: 2rem;
  position: relative;
}

@media screen and (max-width: 767px) {
  .wrapper {
    display: block;
  }
  .wrapper .left-side {
    transform: translateX(-100%);
    transition: all 0.5s ease;
  }
  .wrapper .left-side.active {
    transform: translateX(0);
  }
  .wrapper .right-side {
    padding-left: 0;
  }
  .wrapper .right-side > .content {
    padding: 1rem;
  }
  .wrapper .right-side .content-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 1rem;
  }
  .wrapper .right-side .content-header h1 {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
  }
}
.auth-wrap {
  height: 100vh;
  background-color: #34495E;
  position: relative;
  padding-top: 50px;
}
.auth-wrap .shape-1 {
  position: absolute;
  top: 0;
  right: 0;
}
.auth-wrap .shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.auth-head {
  text-align: center;
}
.auth-head .logo {
  margin-bottom: 20px;
}
.auth-head .logo img {
  max-width: 120px;
}
.auth-head p {
  margin: 0;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
}
.auth-head h1 {
  margin: 0;
  color: #FFF;
  font-family: "Barlow", sans-serif;
  font-size: 1.8rem;
}

.auth-card {
  border: 1px solid #dee2e6;
  position: relative;
  padding: 2rem;
  background-color: #f4f4f4;
  width: 400px;
  margin: auto;
  border-radius: 0.4rem;
  margin-top: 2rem;
  position: relative;
  z-index: 1;
}
.auth-card h2 {
  font-weight: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

.auth-right p {
  color: rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 767px) {
  .auth-card {
    width: 90%;
  }
}
.welcome-wrap {
  height: 100vh;
  background-color: #34495E;
  position: relative;
  padding-top: 20px;
}
.welcome-wrap .shape-1 {
  position: absolute;
  top: 0;
  right: 0;
}
.welcome-wrap .shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.welcome-head {
  text-align: center;
}
.welcome-head .logo {
  margin-bottom: 20px;
}
.welcome-head .logo img {
  max-width: 120px;
}
.welcome-head p {
  margin: 0;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
}
.welcome-head h1 {
  margin: 0;
  color: #FFF;
  font-family: "Barlow", sans-serif;
  font-size: 1.8rem;
}

.welcome-card {
  border: 1px solid #dee2e6;
  position: relative;
  background-color: #f4f4f4;
  width: 1200px;
  margin: 1rem auto 0 auto;
  border-radius: 0.4rem;
}
.welcome-card .title {
  font-weight: normal;
  color: black;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.welcome-card .welcome-content {
  padding: 1rem 3rem;
}
.welcome-card .welcome-content h1 {
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.welcome-card .welcome-content p {
  color: rgba(0, 0, 0, 0.6);
}
.welcome-card .welcome-content .ready {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
}
.welcome-card .welcome-content .ready h3 {
  font-size: 1.5rem;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
}
.welcome-card .welcome-content .ready h3 span {
  color: black;
}
.welcome-card .welcome-content .ready div a {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
}
.welcome-card .welcome-content .ready div a i {
  margin-left: 0.5rem;
}

.auth-right p {
  color: rgba(0, 0, 0, 0.4);
}

.table-va-m tr td,
.table-va-m tr th {
  vertical-align: middle;
}

.l-info {
  margin: 8px 0 4px 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}
.l-info li {
  margin-right: 2rem;
  display: flex;
  align-content: center;
}
.l-info li i {
  margin-right: 6px;
}
.l-info li span {
  display: inline-block;
  padding: 0px 8px;
  border-radius: 4px;
  margin-left: 5px;
  background-color: rgba(0, 0, 0, 0.08);
  color: #E64209;
}

.card-dashboard-info {
  position: relative;
}
.card-dashboard-info > img {
  max-width: 100px;
  position: absolute;
  right: 10px;
  top: 20px;
}
.card-dashboard-info ul {
  margin: 2rem 0 0 0;
  padding: 0;
  list-style-type: none;
}
.card-dashboard-info ul li {
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 1rem;
}
.card-dashboard-info ul li p {
  margin: 0 0 0.2rem 0;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.9rem;
}
.card-dashboard-info ul li h4 {
  margin: 0;
  font-size: 1.5rem;
}
.card-dashboard-info ul li:last-child {
  border-bottom: 0;
}

.main-stats {
  margin: 1rem 0 0 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}
.main-stats li {
  flex: 1;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  padding: 1rem 0;
}
.main-stats li p {
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
}
.main-stats li:last-child {
  border-right: 0;
}

.stock-list {
  margin: 1rem 0 0 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.stock-list.items-4 li {
  flex-basis: 22%;
}
.stock-list li {
  padding: 1rem;
  flex-basis: 48%;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  margin-bottom: 1rem;
}
.stock-list li p {
  color: rgba(0, 0, 0, 0.5);
  margin: 0.5rem 0 0 0;
  line-height: 1.3rem;
}
.stock-list li h4 {
  margin: 0;
  font-size: 1.2rem;
}
.stock-list li:last-child {
  border-bottom: 0;
}

@media screen and (max-width: 767px) {
  .main-stats {
    flex-direction: column;
  }
  .main-stats li {
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
  .main-stats li:last-child {
    border-bottom: none;
  }
}

