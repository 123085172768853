.card {
	border-radius: $radius;
	// border: none;

	.card-title-dash {
		@include textBlack(0.6);
		border-bottom: 1px solid rgba(0, 0, 0, .2);
	    padding-bottom: 1rem;
	    text-transform: uppercase;
	    font-weight: 600;
	    font-size: 1rem;
	}

	


	&.card-welcome {
		padding: .5rem 1.5rem;
		// img {
		// 	position: absolute;
  //   		max-width: 250px;
  //   		right: 0;
  //   		top: -72px;
		// }

		p {
			margin: 0;
		}
		h4 {
			font-family: $font-secondary;
			font-size: 2rem;
			span {
				@include textBlack(0.5);
				font-weight: 300;
			}
		}

		.logged-in {
			@include textBlack(0.4);
			span {
				color: $primary;

				font-weight: 500;
			}
		}
	}
}

.card-title {
	margin-bottom: .9rem;
	font-size: 1rem;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: rgba(0,0,0,.6);

	position: relative;

	.card-action {
		position: absolute;
		right: 0;
		top: 0;

		.button {
			padding: 0.1rem 0.8rem;
			font-size: .8rem;
		}
	}
}

.equal-cards {
	.card {
		min-height: 120px;
	}
}

.card-title-2 {
	position: relative;
	font-family: $font-secondary;
	font-size: 1.2rem;
	display: flex;
	padding-left: 2rem;
	i {
		margin-right: 0.4em;
		color: $secondary;
		position: absolute;
		left: 0;
		top: -3px;
		font-size: 1.5rem;
	}
}