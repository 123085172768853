.sugguestions-wrap {
    position: relative;
}
.suggestion-box {
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: #f6f6f6;
    z-index: 1;
    padding: 10px;
    border: 1px solid rgba(0,0,0,0.3);
    border-radius: $radius;
    
}

.user_suggestion {
    .user {
        display: flex;
        align-items: center;
        padding: 10px 0;
        cursor: pointer;
        .photo {
            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }
        }
        .info {
            padding-left: 1rem;
            h3 {
                font-size: 1rem;
                margin: 0;
            }
            p{
                font-size: .9rem;
                margin: 0;
            }
        }
    }
}