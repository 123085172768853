.button {
	display: inline-block;
	vertical-align: middle;
	padding: .6rem 1.3rem;
	border: none;
	text-align: center;
	transition: all 0.4s ease-in-out;
	border: 1px solid transparent;
	font-size: 1rem;
	border-radius: 60px;
	font-weight: 600;
	text-transform: uppercase;
	white-space: nowrap;
	&.hover-on-dark {
		&:hover {
			color: #FFF;
			border-color: #FFF;
		}
	}
}


.button-long {
	min-width: 130px;
}

.button-dark {
	background-color: $themeDark;
	color: #FFF;
	&:hover {
		background-color: transparent;
		border-color: $themeDark;
		color: $themeDark;
	}
}

.button-icon {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.6rem 0.6rem 0.6rem 1.3rem;
	white-space: nowrap;
	span {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.2rem;
	}

	&.icon-right {
		span {
			margin-left: 0.8rem;
		}
	}

	&.icon-left {
		flex-direction: row-reverse;
		justify-content: center;
		padding: 0.6rem 1rem 0.6rem 1rem;
		span,i {
			margin-left: 0;
			margin-right: 0.4rem;
		}
	}

	&.button-dark {
		span {
			background-color: $themeLight;
			color: $themeDark;
		}
	}
}

.button-white {
	background-color: #FFF;
	box-shadow: 0 3px 20px #0000000b;
	color: #000;
}

.button-light {
	background-color: #E8E8E8;
	color: #65676B;
	&:hover {
		background-color: transparent;
		border-color: #65676B;
		color: #65676B;
	}
}

.btn:focus {
	outline: none;
	box-shadow: none;
}


.button-primary {
	background-color: $primary;
	color: #FFF;
	
	font-weight: bold;
	border: 1px solid transparent;
	&:hover {
		background-color: transparent;
		border-color: $primary;
		color: $primary;
		opacity: 1;
	}


}

.button-secondary {
	background-color: $secondary;
	color: #FFF;
	
	font-weight: bold;
	&:hover {
		background-color: transparent;
		border-color: $secondary;
		color: $secondary;
		opacity: 1;
	}
}


.button-outline-secondary {
	border-color: $secondary;
	color: $secondary;

	&:hover {
		color: #FFF;
		background-color: $secondary;
	}
}


.button-outline-primary {
	border-color: $primary;
	color: $primary;
	background-color: transparent;
	&:hover {
		color: #FFF;
		background-color: $primary;
	}
}



.button-fb {
	background-color: #3C5A9A;
	color: #FFF;
	
	font-weight: bold;
	&:hover {
		background-color: transparent;
		border-color: #3C5A9A;
		color: #3C5A9A;
		opacity: 1;
	}
}

.button-success {
	background-color: $green;
	color: #FFF;

	&:hover {
		color: #FFF;
		opacity: .8;
	}
}


.button-danger {
	background-color: $red;
	color: #FFF;
	&:hover {
		color: #FFF;
		opacity: .8;
	}
}

.button-round {
	border-radius: $radius;
}


.button-block {
	width: 100%;
	display: block;
}

.button-outline-gray {
	background-color: #F5F5F5;
	border: 1px solid #707070;
	color: #292724;
	font-weight: bold;
	
	&:hover {
		color: #292724;
		background-color: #FFF;
	}
}



.button-large-icon {
	display: flex;
	flex-direction: column;
	line-height: 3rem;
	.icon {
		font-size: 3rem;
	}
	.label {
		font-size: 1.5rem;
	}
}