.user_item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding: 0.8rem 2rem;
    position: relative;

    &:hover {
        background-color: rgba(0,0,0,0.02);
    }

    // > a {
    //     display: block;
    //     width: 100%;
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     height: 100%;
    // }

    > svg {
        display: none;
        // position: absolute;
        // top: 50%;
        // right: 1.8rem;
        // margin-top: -8px;
    }

    &:last-child {
        border-bottom: none;
    }
    .user {
        display: flex;
        align-items: center;
        flex-basis: 50%;
        .photo {
            width: 80px;
            img {
                width: 60px;
                height: 60px;
                border-radius: 50%;
            }
        }
        .info {
            h3 {
                margin: 0;
                font-size: 1.2rem;
            }
            p {
                margin: 0;
            }
        }
    }

    .amount {
        font-size: 1.3rem;
        min-width: 135px;
        font-weight: 500;
    }
}

@media screen and (max-width: 767px) {
    .user_item {
        padding: 1rem 0.4rem;
        flex-wrap: wrap;
        justify-content: center;
        .user {
            .photo {
                width: 58px;
                img {
                    width: 50px;
                    height: 50px;
                }
            }
            .info {
                p {
                    font-size: .8rem;
                }
            }
            
        }

        .button {
            margin-top: .4rem;
            font-size: .8rem;
            padding: 0.4rem 0.8rem;
        }
    }
}