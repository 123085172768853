header {
    background-color: #FFF;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    padding: .1rem 0;


    .navbar-toggler {
        color: #FFF !important;
        font-size: 46px;
        padding: 0;
        border: none;
    }

    .navbar-brand {
        img {
            max-width: 140px;
        }
    }

    .notifications {
        margin-right: 2rem;
        
        a {
            position: relative;
            span {
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: $red;
                color: #FFF;
                position: absolute;
                right: -10px;
                top: -4px;
                font-size: 1rem;
            }
            i {
                font-size: 2rem;
            }
        }
    }

    .user-logged-in {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .photo {
            margin-right: 0.6rem;
            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                cursor: pointer;
            }
            a {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: $secondary;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;

                &:hover,
                &:focus {
                    background-color: $primary;
                    color: #FFF;
                }
            }
        }
        .info {
            h4 {
                font-size: 1.1rem;
                margin:0;
            }
            p {
                font-size: .9rem;
                margin: 0;
            }
        }

    }

    .nav-right {
        .nav-item {
            .nav-link {
                color: $primary;
                text-transform: uppercase;
                font-size: 1rem;
                padding-right: 2.5rem;
                font-weight: 500;

                &.active,
                &:hover,
                &:focus {
                    color: $secondary;
                }
            }

            .button-sm {
                font-size: .9rem;
                font-weight: normal;
                padding: 0;
                padding: .5rem 1rem;
            }
        }
    }
}

#toggleSideNav {
    display: none;
}

@media screen and (max-width: 767px) {
    .header {
        .nav-right {
            display: none;
        }
        .navbar-primary {

            .header-pump-code {
                margin-left: 0;
                color: #FFF;
                font-weight: normal;
                font-size: .7rem;

                span {
                    display: none;
                }
            }


            .container-fluid {
                flex-wrap: nowrap;

                #toggleSideNav {
                    display: block;
                    color: $secondary;
                    font-size: 1.8rem;

                    .close-icon {
                        display: none;
                    }

                    &.close {
                        .menu-icon {
                            display: none;
                        }
                        .close-icon {
                            display: block;
                        }
                    }

                    
                }
            }
        }
    }
}