.card-dashboard-info {
	position: relative;
	> img {
		max-width: 100px;
		position: absolute;
		right: 10px;
		top: 20px;
	}

	ul {
		margin: 2rem 0 0 0;
		padding: 0;
		list-style-type: none;

		li {
			margin-bottom: 1rem;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			padding-bottom: 1rem;
			p {
				margin: 0 0 .2rem 0;
				@include textBlack(0.5);
				font-size: .9rem;
			}
			h4 {
				margin: 0;
				font-size: 1.5rem;
			}

			&:last-child {
				border-bottom: 0;
			}
		}
	}
}

.main-stats {
	 margin: 1rem 0 0 0;
	 padding: 0;
	 list-style-type: none;
	 display: flex;
	 li {
	 	flex: 1;
	 	text-align: center;
	 	border-right: 1px solid rgba(0, 0, 0, 0.08);
	 	padding: 1rem 0;
	 	p {
	 		@include textBlack(0.5);
	 		margin: 0;
	 	}

	 	&:last-child {
				border-right: 0;
			}

	 }
}

.stock-list {
	margin: 1rem 0 0 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	&.items-4 {
		li {
			flex-basis: 22%;
		}
	}

	li {
		padding: 1rem;
		flex-basis: 48%;
		background-color: rgba(0,0,0,0.04);
		border-radius: 10px;
		margin-bottom: 1rem;

		p {
			@include textBlack(0.5);
	 		margin: .5rem 0 0 0;
	 		line-height: 1.3rem;
		}

		h4 {
			margin: 0;
			font-size: 1.2rem;
		}

		&:last-child {
				border-bottom: 0;
			}
	}
}

@media screen and (max-width: 767px) {
	.main-stats {
		flex-direction: column;
		li {
			border-right: none;
			border-bottom: 1px solid rgba(0, 0, 0, 0.08);

			&:last-child {
				border-bottom: none;
			}
		}
	}
}