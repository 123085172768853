.table {
    thead {
        tr {
            th {
                font-size: .9rem;
            }
        }
    }
}


.va-middle {

    td,
    th {
        vertical-align: middle;
    }
}

.table-add-sale {
    .col-input {
        max-width: 70px;
    }
}


.scrollview {
    overflow-x: hidden;
    overflow-y: scroll;
}


/* Fix table head */
.tableFixHead    { 
    th { 
        position: sticky; 
        top: 0; 
        background-color: #FFF;
        z-index: 1;
    }
}


.table-notifications {
    .userinfo {
        display: flex;
        .photo {
            width: 50px;
            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }
        }
        .info {
            h3 {
                font-size: 1.2rem;
                margin: 0;
            }
            p{
                font-size: .9rem;
                margin: 0;
            }
        }
    }

    .button {
        font-size: .9rem;
        padding: 0.3rem .7rem;
    }

    .action-buttons {
        display: flex;
        align-items: center;
    }
}


.table-card {
    border-spacing: 0 10px;
    border-collapse: separate;

    thead {
        tr {
            background-color: rgba(0, 0, 0, 0.08);
            th {
                padding: .75rem 1.25rem;
                font-weight: bold;
                white-space: nowrap;
            }
        }
    }

    tbody {
        tr {
            td {
                box-shadow: 20px 3px 20px #0000000b;
                padding: .75rem 1.25rem;
                border-bottom-width: 0px;
                background-color: rgba(255, 255, 255, 1.0);
            }
        }
    }

    td {
        vertical-align: middle;
    }
}


@media screen and (max-width: 767px) {




    .tbl-responsive {


        border: 0;

        &.table-striped {
        	tr {
        		td {
        			--bs-table-accent-bg: #FFF;
        		}
        	}
        }



        thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 1rem;
            box-shadow: rgba(26, 108, 225, 0.1) 0px 0px 25px;
            background-color: #FFF;
            padding: 18px;
            border-radius: 5px;
        }

        td {
            border-bottom: 1px solid #ddd;
            display: block;
            text-align: right;
            padding: .3rem .1rem;

            &::before {
                content: attr(data-label);
                float: left;
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }
}