.theme-tabs {
	border: none;
	.nav-item {
		.nav-link {
			background-color: #e8eaf2;
			color: #2b2b2b;
			font-size: 1.1em;
			font-weight: 500;
			padding: 1em 2em;
			border: none;
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
			&.active,
			&:hover,
			&:focus {
				background-color: $primary;
				color: #FFF;
				box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
			}
		}
	}
}

#myTabContent {
	margin-top: -4px;
	z-index: 1;
	position: relative;
}

.tab-content-inner {
	background-color: #FFF;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
	border-radius: 6px;
	padding: 3em 2em;
}


.tab-style-2 {
	justify-content: center;
	.nav-item {
		
		.nav-link {
			text-align: center;
			color: rgba(0, 0, 0, .6);
			font-weight: 600;
			border-top: none;
			border-right: none;
			border-bottom: 3px solid #eceeef;
			border-left: none;
			padding: 15px 60px 10px 60px;
			font-size: 1.2rem;
			
			span {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 auto 10px  auto;
				color: $themeBlack;
				font-size: 1rem;
				font-weight: 400;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				font-weight: bold;
				background-color: $themeLight;
			}
			&.active,
			&:hover {
				border-bottom-color: $primary;
				color: $primary;
				span {
					background-color: $primary;
					color: #FFF;
				}
			}
		}
	}
}

.tab-style-3 {
	.nav-item {
		
		.nav-link {
			text-align: center;
			color: $primary;
			font-weight: normal;
			border-top: none;
			border-right: none;
			border-bottom: 5px solid transparent;
			border-left: none;
			padding: 5px 40px;
			span {
				display: block;
				color: $themeBlack;
				border-bottom: 3px solid #FFF;
			}
			&.active,
			&:hover {
				background-color: $primary;
				color: #FFF;
				span {
					color: #FFF;
				}
			}
		}
	}
}