body {
	font-family: $font-primary;
	background-color: $themeLight;
	font-weight: normal;
	
}

a {
	color: $primary;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		color: $secondary;
	}
}

#main {
	padding-top: 8rem;
}

// @media screen and (max-width: 767px) {
// 	body {
// 		padding-top: 4rem;
// 	}
// }