.card_button {
    display: flex;
    background-color: #FFF;
    padding: 2rem;
    border-radius: $radius;
    position: relative;
    border: 2px solid transparent;
    transition: all 0.5s ease;

    &:hover {
        border-color: $primary;
    }

    > a {
        display: block;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;  
    }
    .chevron {
        position: absolute;
        right: 2rem;
        top: 50%;
        margin-top: -8px;
    }
    .icon {
        width: 70px;

    }
    .info {
        padding-left: 1rem;
        p {
            margin: 0;
        }
        h3 {
            margin: 0;
            font-weight: bold;
        }
    }
}