.simple-section {
    padding: 1rem 0;
    position: relative;
}

.section-title {
    font-size: 2.27rem;
    font-weight: 600;
    color: $themeBlack;
    text-align: center;
    position: relative;
    margin-bottom: 3rem;
    font-family: $font-secondary;
    &::after {
        content: "";
        display: block;
        width: 80px;
        height: 3px;
        background-color: $primary;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -12px;
        margin: auto;
        border-radius: 1rem;
    }

    &.title-sm {
        font-size: 1.2rem;
        margin-bottom: 2rem;
    }

    // &::before {
    //     content: "";
    //     display: block;
    //     width: 30px;
    //     height: 38px;
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     bottom: -35px;
    //     margin: auto;
    //     animation: move4 5s infinite linear;
    //     background-size: 100%;
    //     background-repeat: no-repeat;
    // }

    &.title-left {
        text-align: left;
        &::after {
            margin-left: 0;
        }
    }

  
}


.text-red {
    color: $red;
}
.text-green {
    color: $green;
}

.link_back {
    display: inline-flex;
    align-items: center;
    svg {
        margin-right: .8rem;
    }

    
}




.list-bullets {
    margin: 0;
    padding: 0;
    list-style: none;
    list-style-type: none;

    li {
        @include textBlack(0.5);
        font-size: 1.125rem;
        position: relative;
        padding-left: 1.4rem;

        &::before {
            font-family: remixicon !important;
            font-style: normal;
            -webkit-font-smoothing: antialiased;
            content: "\EB7C";
            position: absolute;
            left: 0;
            top: 0;
            color: $primary;
            font-size: .7rem;
            top: .4rem;
        }
    }

    &.link-white {
        li {
            color: #FFF;

            &::before {
                color: #FFF;
            }
        }
    }
}

.text-right {
    text-align: right;
}


@media screen and (max-width: 767px) {
    .section-title {
        font-size: 2rem;
    }

    .dash-head {
        .breadcrumb {
            justify-content: center;
            border-bottom: 1px solid rgba(255, 255, 255, .3);
            padding-bottom: 1rem;
        }
    }

    .body-wrap {
        padding-top: 5rem;
    }
}



.alert {
    border-radius: $radius;
}