.input-icon {
  position: relative;

  .form-control {
    min-height: 52px;
    border-radius: 50px;
    padding-left: 4rem;
    color: $primary;
    &:focus {
      outline: 1px solid $primary;
      box-shadow: none;
    }
  }

  span {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(215, 36, 29, 0.2);
    position: absolute;
    left: 10px;
    top: 8px;
    border-radius: 50%;
    color: $themeDark;
    font-size: 1.6rem;
  }
}

.form-control,
.form-select {
  &:focus {
      outline: 1px solid $primary;
      box-shadow: none;
  }

  &:focus ~ label {
    color: $primary;
  }
}


#CountryCodes {
  width: 100%;
  height: 56px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#inputMobile {
  border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;
}

.form-group {
  position: relative;
  label {
    margin-bottom: 0.3rem;
    @include textBlack(0.9);
    font-weight: bold;
    
  }
  .form-control,
  .form-select {
    // border-radius: 40px;
    min-height: 56px;
    // padding-left: 1.8rem;

    &:placeholder {
      @include textBlack(0.4);
    }
  }


  .input-action {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: $secondary;
    border: none;
    position: absolute;
    right: 5px;
    top: 34px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $primary;
      svg {
        path {
          stroke: #FFF;
        }
      }
    }
  }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.radio-logos {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.radio-logos li  {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 2rem 0 0 0;
    border-radius: $radius;
    position: relative;
    background-color: #FFF;
    flex-basis: 14%;
    margin: 1%;
    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }

    input {
        opacity: 0;
        position: absolute;
        left: 0;

        &+label {
            display: block;
            cursor: pointer;
            text-align: center;

            &::before {
                font-family: remixicon !important;
                font-weight: 400;
                content: "\EB7D";
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                margin: auto;
                font-size: 1.3rem;
            }

            img {
              max-width: 60px;
              margin-bottom: .5rem;
            }
        }



        &:checked+label {
            &::before {
                content: "\EB80";
                color: $primary;
            }

            .count {
                background-color: $secondary;
                color: #FFF;
            }
        }
    }

}