//colors
$primary: #34495E;
$secondary: #AACFD0;
$green: #49B76F;
$red: #F2555E;
$themeBlack: #121606;
$themeLight: #EBEDF4;
$themeDark: #191824;
$lightGray: #F8F8F8;
$warning: #F4B71E;
$gray: #7E7E7E;


$color-petrol: #549B8C;
$color-diesel: #BD9B16;

// fonts

$font-primary: 'Barlow', sans-serif;
$font-secondary: 'Barlow', sans-serif;


// general

$radius: .4rem;
