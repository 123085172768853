.breadcrumb {
	
	margin: .4rem 0 0 0;
	padding: 0;

	li {
		font-size: .8rem;
		a {
			color: $themeBlack;
		}

		&.active {
			@include textBlack(0.5);
		}

		
	}
}

.breadcrumb-item+.breadcrumb-item::before {
	@include textBlack(0.5);
}

@media screen and (max-width: 767px) {
	.breadcrumb {
		li {
			
			
		}
	}
}